var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom-info" }, [
    _c("div", [
      _c("span", [_vm._v("Powered by")]),
      _c("span", [
        _c("a", { attrs: { href: "#" } }, [_vm._v(_vm._s(_vm.copyright))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }