//
//
//
//
//
//
//
//
//
//
export default {
  name: "BottomInfo",
  data: function data() {
    return {
      copyright: process.env.VUE_APP_COPYRIGHT
    };
  }
};